import React from 'react';
import { Flex, Pagination } from '@decisiv/ui-components';
import PropTypes from 'prop-types';

const PaginationFooter = ({
  page,
  pageSize,
  totalItems,
  totalPages,
  onPageChange,
}) => (
  <Flex
    id="pagination-navigation"
    flex={1}
    alignItems="center"
    justifyContent="space-between"
  >
    <Flex>
      <span>
        {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, totalItems)} of{' '}
        {totalItems} Users
      </span>
    </Flex>
    <Pagination
      onPageChange={onPageChange}
      totalPages={totalPages}
      perPage={pageSize}
      size="small"
      activePage={page}
    />
  </Flex>
);

PaginationFooter.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationFooter;

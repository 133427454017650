import { render } from '@testing-library/react';
import React from 'react';
import AssociatedLocations from './AssociatedLocations';

jest.mock('./AssociatedLocationsTable', () => () =>
  '{AssociatedLocationsTable}',
);

describe('AssociatedLocations', () => {
  const renderAssociatedLocations = (props) =>
    render(<AssociatedLocations {...props} />);
  const props = {
    dealerId: 9999,
    user: { id: 10001, email: 'test@user.email' },
  };

  it('renders the component', () => {
    const { container } = renderAssociatedLocations(props);
    expect(container).toMatchSnapshot();
  });
});

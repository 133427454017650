export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';
export const UNSORTED = 'unsorted';

export const createSortHandler = (setSortBy, setSortOrder) => (columnName) => (
  sortingType,
) => {
  switch (sortingType) {
    case ASCENDING:
      setSortBy(columnName);
      setSortOrder('ASC');
      break;
    case DESCENDING:
      setSortBy(columnName);
      setSortOrder('DESC');
      break;
    case UNSORTED:
      setSortBy(null);
      setSortOrder(null);
      break;
    default:
      setSortBy(null);
      setSortOrder(null);
      break;
  }
};

import React, { useEffect, useState } from 'react';
import { Flex, Button, Message, Tooltip, H2, P } from '@decisiv/ui-components';
import Pencil from '@decisiv/iconix/lib/components/Pencil';
import PatchedTable from 'components/PatchedTable';
import PropTypes from 'prop-types';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import LoadingState from './LoadingState';
import EmptyState from './EmptyState';
import PaginationFooter from './PaginationFooter';
import { createSortHandler } from '../utils/sortUtils';
import useUsers from '../hooks/useUsers';

const CurrentAccess = ({ dealerId, isGlobalAdmin, csrfToken }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [hasWarnings, setHasWarnings] = useState(false);

  const become = async (url) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    });

    if (response.ok) {
      window.location.href = response.url;
    }
  };

  const {
    users,
    isLoading,
    pagination: { perPage, totalItems, totalPages },
    fetchUsersData,
  } = useUsers({ dealerId, endpoint: 'active_access' });

  useEffect(() => {
    fetchUsersData({ page, sortBy, sortOrder });
  }, [fetchUsersData, page, sortBy, sortOrder]);

  useEffect(() => {
    setHasWarnings(users.some((user) => user.show_warning));
  }, [users]);

  const onSort = createSortHandler(setSortBy, setSortOrder);

  const columns = [
    {
      DataCell: ({ rowData }) => (
        <Flex>
          <Flex marginRight={1} style={{ width: '15px' }}>
            <Tooltip
              target={
                <Message
                  intent="danger"
                  size="small"
                  color="warning"
                  style={{
                    visibility: rowData.show_warning ? 'visible' : 'hidden',
                  }}
                />
              }
              placement="right"
            >
              User has been inactive for over 6 months and may be vulnerable to
              security threats. Consider removing access.
            </Tooltip>
          </Flex>
          <a
            href={`/users/edit/${rowData.id}`}
            style={{
              textDecoration: 'underline',
              fontSize: '12px',
            }}
          >
            {rowData.username}
          </a>
        </Flex>
      ),
      name: 'username',
      sortBy: onSort('username'),
      title: 'USERNAME',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.name}</span>,
      name: 'name',
      sortBy: onSort('fullname'),
      title: 'NAME',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.job_role}</span>,
      name: 'job_role',
      sortBy: onSort('job_role'),
      title: 'JOB ROLE',
    },
    {
      DataCell: ({ rowData }) => (
        <span>
          {rowData.permissions ? rowData.permissions.join(', ') : '—'}
        </span>
      ),
      name: 'permissions',
      title: 'PERMISSIONS',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.last_login_at || '—'}</span>,
      name: 'lastLogin_at',
      sortBy: onSort('last_login_at'),
      title: 'LAST LOGIN',
    },
    {
      DataCell: ({ rowData }) => (
        <>
          <Flex>
            <Tooltip
              placement="top"
              target={
                <Button
                  aria-label="Edit"
                  icon={Pencil}
                  size="small"
                  kind="secondary"
                  onClick={() => {
                    window.location.href = `/users/edit/${rowData.id}`;
                  }}
                />
              }
            >
              Edit User
            </Tooltip>
          </Flex>
          {isGlobalAdmin && (
            <Flex marginLeft={0.5}>
              <Button
                text="BECOME"
                size="small"
                kind="secondary"
                onClick={() => {
                  become(`/users/${rowData.id}/become`);
                }}
              />
            </Flex>
          )}
        </>
      ),
      getCellKey: ({ id }) => `${id}-actions`,
      name: 'actions',
      title: 'ACTIONS',
    },
  ];

  const getRowKey = ({ id }) => id;

  const footer = () => (
    <PaginationFooter
      page={page}
      pageSize={perPage}
      totalItems={totalItems}
      totalPages={totalPages}
      onPageChange={(newPage) => setPage(newPage)}
    />
  );

  const Header = () => (
    <Flex marginTop={2} marginBottom={1}>
      <H2>Current Access</H2>
      {hasWarnings && (
        <Flex alignItems="center" marginLeft={2}>
          <ExclamationTriangleF
            color={toColorString(color.status.warning.medium)}
          />
          <P color="alaskanHusky" style={{ marginLeft: '5px' }}>
            User has been inactive for over 6 months and may be vulnerable to
            security threats. Consider removing access.
          </P>
        </Flex>
      )}
    </Flex>
  );

  if (isLoading) {
    return (
      <>
        <Header />
        <LoadingState />
      </>
    );
  }

  if (users.length === 0) {
    return (
      <>
        <Header />
        <EmptyState title="No Users Added Yet" />
      </>
    );
  }

  return (
    <>
      <Header />
      <PatchedTable
        columns={columns}
        data={users}
        getRowKey={getRowKey}
        footer={footer}
      />
    </>
  );
};

CurrentAccess.propTypes = {
  dealerId: PropTypes.number.isRequired,
  isGlobalAdmin: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default CurrentAccess;

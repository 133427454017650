import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@decisiv/ui-components';
import { GlobalStyle, CssResetStyle } from '../AssociatedLocations/components/styledComponents';
import ActionModal from '../AssociatedLocations/components/ActionModal';
import iconix from '@decisiv/iconix';
import useAccessActionModalSuccess from '../AssociatedLocations/useAccessActionModalSuccess';

const ManageAccessButton = ({
  dealers,
  user,
  initialActionType,
  csrfToken,
  onSuccessCallback,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState(initialActionType);

  useAccessActionModalSuccess((event) => {
    if (event.dealer_ids.includes(dealers[0].id)) {
      handleSuccess(event.message);
    }
  });

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleSuccess = (message) => {
    setActionType((prev) => (prev === 'remove' ? 'restore' : 'remove'));

    if (onSuccessCallback && typeof window[onSuccessCallback] === 'function') {
      window[onSuccessCallback](message);
    }
  };

  const buttonText =
    actionType === 'remove' ? 'Remove Access' : 'Restore Access';

  return (
    <>
      <CssResetStyle />
      <GlobalStyle />
      <Button
        onClick={toggleModal}
        text={buttonText}
        kind="secondary"
        size="small"
        icon={actionType === 'remove' ? iconix.UserTimes : iconix.UserPlus}
        {...(actionType === 'remove' && { intent: 'danger' })}
      />

      <ActionModal
        visible={isModalOpen}
        handleCloseModal={toggleModal}
        dealers={dealers}
        user={user}
        actionType={actionType}
        csrfToken={csrfToken}
      />
    </>
  );
};

ManageAccessButton.propTypes = {
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  initialActionType: PropTypes.oneOf(['remove', 'restore']).isRequired,
  csrfToken: PropTypes.string.isRequired,
  onSuccessCallback: PropTypes.string,
};

ManageAccessButton.defaultProps = {
  onSuccessCallback: undefined,
};

export default ManageAccessButton;

import { useEffect } from 'react';

const useAccessActionModalSuccess = (callback) => {
  useEffect(() => {
    const handler = (event) => {
      callback(event.detail);
    };

    window.addEventListener('accessActionModalSuccess', handler);
    return () => {
      window.removeEventListener('accessActionModalSuccess', handler);
    };
  }, [callback]);
};

export default useAccessActionModalSuccess;

import { Tab, Tabs } from '@decisiv/ui-components';
import React from 'react';
import PropTypes from 'prop-types';
import CurrentAccess from './components/CurrentAccess';
import RemovedAccess from './components/RemovedAccess';
import { CssResetStyle } from '../AssociatedLocations/components/styledComponents';

const Users = ({ dealerId, isGlobalAdmin, renderedPartial, csrfToken }) => {
  return (
    <>
      <CssResetStyle />

      <Tabs defaultSelectedId="current-access" onChange={showOrHideUsersDiv}>
        <Tab id="current-access" title="Current Access">
          <CurrentAccess
            dealerId={dealerId}
            isGlobalAdmin={isGlobalAdmin}
            csrfToken={csrfToken}
          />
        </Tab>
        <Tab id="removed-access" title="Removed Access">
          <RemovedAccess dealerId={dealerId} />
        </Tab>
        {isGlobalAdmin && (
          <Tab id="supported" title="Supported/Watched By">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: renderedPartial }}
            />
          </Tab>
        )}
      </Tabs>
    </>
  );
};

const showOrHideUsersDiv = (selectedTab) => {
  const externalDiv = document.getElementById('user_find_or_create_form');
  if (externalDiv) {
    externalDiv.style.display =
      selectedTab === 'current-access' ? 'block' : 'none';
  }
};

Users.propTypes = {
  dealerId: PropTypes.number.isRequired,
  isGlobalAdmin: PropTypes.bool.isRequired,
  renderedPartial: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default Users;

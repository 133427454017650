const fetchUsers = async ({
  dealerId,
  endpoint,
  page = 1,
  sortBy = null,
  sortOrder = null,
}) => {
  let url = `/dealers/${dealerId}/${endpoint}?page=${page}`;

  if (sortBy) {
    url += `&sort_by=${sortBy}&sort_order=${sortOrder}`;
  }

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const usersData = await response.json();
  return {
    users: usersData.users,
    pagination: {
      page: usersData.pagination.page,
      perPage: usersData.pagination.per_page,
      totalItems: usersData.pagination.total_entries,
      totalPages: usersData.pagination.total_pages,
    },
  };
};

export default fetchUsers;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { H2, P, Button } from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';
import AssociatedLocationsTable from './AssociatedLocationsTable';
import { GlobalStyle } from './styledComponents';
import { t } from '../utils/translations';
import ActionModal from './ActionModal';
import useAccessActionModalSuccess from '../useAccessActionModalSuccess';

function AssociatedLocations({ currentDealerId, user, csrfToken }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [actionType, setActionType] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isRemovedAccessVisible, setRemovedAccessVisible] = useState(false);

  useAccessActionModalSuccess(() => {
    setRefreshTrigger((prev) => prev + 1);
  });

  const handleOpenModal = (dealers, action) => {
    setSelectedDealers(dealers);
    setActionType(action);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedDealers([]);
    setActionType('');
  };

  return (
    <>
      <GlobalStyle />
      <H2>{t('current_access_title')}</H2>
      <P color="alaskanHusky" style={{ marginTop: '0px' }}>
        {t('current_access_desc')}
      </P>

      <AssociatedLocationsTable
        currentDealerId={currentDealerId}
        userId={user.id}
        onSingleAction={(dealerId) => handleOpenModal([dealerId], 'remove')}
        onBulkAction={(dealerIds) => handleOpenModal(dealerIds, 'remove')}
        refreshTrigger={refreshTrigger}
      />

      <div style={{ marginTop: '20px' }}>
        <Button
          onClick={() => setRemovedAccessVisible((prev) => !prev)}
          text={
            isRemovedAccessVisible
              ? t('hide_removed_access')
              : t('show_removed_access')
          }
          variant="ghost"
          icon={isRemovedAccessVisible ? iconix.ChevronUp : iconix.ChevronDown}
          iconPosition="right"
          style={{ marginLeft: '5px' }}
        />

        <div style={{ display: isRemovedAccessVisible ? 'block' : 'none' }}>
          <H2 style={{ marginTop: '10px' }}>{t('removed_access_title')}</H2>
          <P color="alaskanHusky" style={{ marginTop: '0px' }}>
            {t('removed_access_desc')}
          </P>
          <AssociatedLocationsTable
            associationsRemoved
            currentDealerId={currentDealerId}
            userId={user.id}
            onSingleAction={(dealer) => handleOpenModal([dealer], 'restore')}
            onBulkAction={(dealers) => handleOpenModal(dealers, 'restore')}
            refreshTrigger={refreshTrigger}
          />
        </div>
      </div>

      <ActionModal
        visible={isModalVisible}
        handleCloseModal={handleCloseModal}
        dealers={selectedDealers}
        user={user}
        actionType={actionType}
        csrfToken={csrfToken}
      />
    </>
  );
}

AssociatedLocations.propTypes = {
  currentDealerId: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

AssociatedLocations.defaultProps = {
  currentDealerId: null,
};

export default AssociatedLocations;

// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AssociatedLocations renders the component 1`] = `
.c0 {
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  line-height: 120%;
}

.c1 {
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #737482;
  font-size: 0.875rem;
  line-height: 140%;
}

.c2 {
  font-family: 'Montserrat',sans-serif;
  margin: 0;
  overflow: visible;
  -webkit-appearance: button;
}

.c2::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.c3 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.875rem;
  height: 2rem;
  padding: 0;
}

.c3:disabled:disabled {
  background-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c4 {
  line-height: 0;
  margin-left: 0.3125rem;
}

<div>
  <h2
    class="c0"
  >
    Current Access
  </h2>
  <p
    class="c1"
    color="alaskanHusky"
    style="margin-top: 0px;"
  >
    User has access to the following locations.
  </p>
  {AssociatedLocationsTable}
  <div
    style="margin-top: 20px;"
  >
    <button
      class="c2 c3"
      style="margin-left: 5px;"
      type="button"
    >
      Show Removed Access
      <span
        class="c4"
      >
        <svg
          color="currentColor"
          fill="none"
          focusable="false"
          height="16"
          viewBox="0 0 16 16"
          width="16"
        >
          <path
            d="M8 12c-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L8 9.6l5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-6 6c-.2.2-.4.3-.7.3z"
            fill="currentColor"
          />
        </svg>
      </span>
    </button>
    <div
      style="display: none;"
    >
      <h2
        class="c0"
        style="margin-top: 10px;"
      >
        Removed Access
      </h2>
      <p
        class="c1"
        color="alaskanHusky"
        style="margin-top: 0px;"
      >
        User no longer has access to the following locations.
      </p>
      {AssociatedLocationsTable}
    </div>
  </div>
</div>
`;

/**
 * We need to patch the KDS Table component because it is assigning `z-index`
 * values to internal elements without properly defining its own stacking
 * context, thus creating glitched interactions with outside elements of the
 * page.
 *
 * If and when the Table is fixed in KDS we can remove this patched version and
 * revert back to using the original component.
 *
 * For more information about the stacking context see:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 *
 * We also wrap the table in a div to prevent a Firefox bug when rendering a
 * table inside a Modal
 * Copy of https://github.com/Decisiv/pricing/blob/master/case-ui-components/src/lib/components/PatchedTable.js
 */

import React from 'react';
import styled from 'styled-components';
import Table from '@decisiv/ui-components/lib/components/Table';

const FixedTable = styled(Table)`
  position: relative;
  z-index: 0;
`;

const PatchedTable = (props) => (
  <div style={{ flex: 1 }}>
    <FixedTable {...props} />
  </div>
);

export default PatchedTable;

import React from 'react';
import { Flex, Message } from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';
import PropTypes from 'prop-types';
import StyledContainer from './styledComponents';

const EmptyState = ({ title, message, icon, size }) => (
  <StyledContainer justifyContent="center">
    <Flex justifyContent="center" flex={1}>
      <Message
        color="licoriceMousse"
        icon={icon}
        size={size}
        title={title}
        variant="vertical"
      >
        <span className="empty-state-message">{message}</span>
      </Message>
    </Flex>
  </StyledContainer>
);

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  icon: PropTypes.func,
  size: PropTypes.string,
};

EmptyState.defaultProps = {
  message: null,
  icon: iconix.UserTimes,
  size: 'medium',
};

export default EmptyState;

import { createGlobalStyle } from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

export const GlobalStyle = createGlobalStyle`
  #notify-success p, #notify-error p {
    font-weight: 600;
  }

  p#notification-body {
    font-weight: 500;
    font-size: 12px;
  }
`;

export const CssResetStyle = createGlobalStyle`
  .rarea nav ul,
  .rarea p,
  .rarea table thead tr th  {
     margin: 0;
  }

  .rarea table thead tr th,
  .rarea table tbody tr td {
    padding: 0;
    vertical-align: top;
  }
`;

export const ulStyle = {
  marginTop: '1rem',
  color: toColorString(color.base.alaskanHusky),
};

export const liStyle = {
  marginBottom: '0.5rem',
};

export const ModalStyle = createGlobalStyle`
  p {
    color: ${toColorString(color.base.alaskanHusky)};
  }

  span {
    font-weight: 600;
  }

  ul {
    marginTop: '1rem';
    color: ${toColorString(color.base.alaskanHusky)};

    li {
      marginBottom: '0.5rem';
    }
  }
`;

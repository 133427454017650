import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@decisiv/ui-components';
import StyledContainer from './styledComponents';

const LoadingState = ({ size }) => (
  <StyledContainer justifyContent="center" flex={1}>
    <Loading size={size} title="Loading..." />
  </StyledContainer>
);

LoadingState.propTypes = {
  size: PropTypes.string,
};

LoadingState.defaultProps = {
  size: 'large',
};

export default LoadingState;

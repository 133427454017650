import { render, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import AssociatedLocationsTable from './AssociatedLocationsTable';

describe('AssociatedLocationsTable', () => {
  const renderAssociatedLocationsTable = (props) =>
    render(<AssociatedLocationsTable {...props} />);
  const props = {
    dealerId: 9999,
    userId: 10001,
  };

  beforeEach(() => {
    global.fetch = jest.fn().mockResolvedValue({
      json: () =>
        Promise.resolve({
          locations: [
            {
              id: 9999,
              name: 'Global Fleet of Volvo',
              permissions: ['Manager', 'Technician'],
              editPath: '/dealers/edit/9999',
              location: 'Greensboro NC',
              removed_at: '01/22/2025 10:51 AM EST',
            },
          ],
          pagination: {
            page: 1,
            per_page: 20,
            total_entries: 60,
            total_pages: 3,
          },
        }),
    });
  });

  afterEach(() => {
    jest.restoreAllMocks();
  });

  describe('when component is loading', () => {
    it('renders the loading state', () => {
      let queryByText;

      act(() => {
        ({ queryByText } = renderAssociatedLocationsTable(props));
      });

      expect(queryByText('Loading...')).toBeInTheDocument();
    });

    it('requests data to the backend', async () => {
      act(() => {
        renderAssociatedLocationsTable(props);
      });

      expect(fetch).toHaveBeenCalledWith('/users/10001/active_access?page=1');
    });
  });

  describe('after component have loaded', () => {
    describe('when there is locations', () => {
      it('renders the locations', async () => {
        let container;

        act(async () => {
          ({ container } = renderAssociatedLocationsTable(props));
        });

        await new Promise(process.nextTick);
        expect(container).toMatchSnapshot();
      });

      describe('when some locations are selected', () => {
        it('enables the action button', async () => {
          let container;
          let getAllByRole;

          act(async () => {
            ({ container, getAllByRole } = renderAssociatedLocationsTable(
              props,
            ));
          });

          await new Promise(process.nextTick);

          const checkboxes = getAllByRole('checkbox');

          act(() => {
            userEvent.click(checkboxes[0]);
          });

          expect(container).toMatchSnapshot();
        });
      });
    });

    describe('when there is no locations', () => {
      it('renders the empty state component', async () => {
        global.fetch = jest.fn().mockResolvedValue({
          json: () =>
            Promise.resolve({
              locations: [],
              pagination: {},
            }),
        });

        let container;

        act(async () => {
          ({ container } = renderAssociatedLocationsTable(props));
        });

        await new Promise(process.nextTick);
        expect(container).toMatchSnapshot();
      });
    });
  });

  describe('when associationsRemoved is true', () => {
    it('requests data to the backend', async () => {
      act(() => {
        renderAssociatedLocationsTable({ ...props, associationsRemoved: true });
      });

      expect(fetch).toHaveBeenCalledWith('/users/10001/removed_access?page=1');
    });

    it('renders the locations', async () => {
      let container;

      act(async () => {
        ({ container } = renderAssociatedLocationsTable({
          ...props,
          associationsRemoved: true,
        }));
      });

      await new Promise(process.nextTick);
      expect(container).toMatchSnapshot();
    });
  });
});

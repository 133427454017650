import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

const StyledContainer = styled.div`
  background-color: ${toColorString(color.base.fullMoon)};
  padding: 20px;
  flex: 1;

  .empty-state-message {
    font-size: 12px;
  }
`;

export default StyledContainer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Modal,
  NotificationsPanel,
  P,
  useNotifications,
} from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';
import { ModalStyle } from './styledComponents';

const ActionModal = ({
  visible,
  handleCloseModal,
  dealers,
  user,
  actionType,
  csrfToken,
}) => {
  const { notify } = useNotifications();

  const isSingleDealer = dealers.length === 1;
  const isActionRemove = actionType === 'remove';
  const action = isActionRemove ? 'Remove Access' : 'Restore Access';
  const loadingText = isActionRemove ? 'REMOVING...' : 'RESTORING...';
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmAction = async () => {
    setIsLoading(true);
    try {
      const endpoint = isActionRemove
        ? `/users/${user.id}/deactivate_for_dealers`
        : `/users/${user.id}/reactivate_for_dealers`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({
          dealer_ids: dealers.map((dealer) => dealer.id),
        }),
      });

      if (response.ok) {
        const {
          message: { title, description },
        } = await response.json();

        const notificationBody = getNotificationBody();

        window.dispatchEvent(
          new CustomEvent('accessActionModalSuccess', {
            detail: {
              dealer_ids: dealers.map((dealer) => dealer.id),
              message: description,
            },
          }),
        );

        notify(
          {
            id: 'notify-success',
            intent: 'success',
            title,
            duration: 15000,
            onClose: () => {},
          },
          <p id="notification-body">{notificationBody}</p>,
        );
      } else {
        const { error } = await response.json();
        notify(
          {
            id: 'notify-error',
            intent: 'danger',
            title: 'Action Failed',
            duration: 15000,
            onClose: () => {},
          },
          <p id="notification-body">{error}</p>,
        );
      }
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const getNotificationBody = () => {
    if (dealers.length === 1) {
      return `${user.username} ${
        isActionRemove
          ? 'can no longer access one location'
          : 'regained access to one location'
      }.`;
    }
    return `${user.username} ${
      isActionRemove
        ? 'was removed from several locations'
        : 'regained access to several locations'
    }.`;
  };

  return (
    <>
      <NotificationsPanel marginTop={4} zIndex={1000} />

      <Modal
        actions={[
          {
            text: action,
            onClick: handleConfirmAction,
            kind: 'primary',
            loading: isLoading,
            loadingText,
            ...(isActionRemove && { intent: 'danger' }),
          },
          {
            text: 'Cancel',
            onClick: handleCloseModal,
            kind: 'secondary',
          },
        ]}
        {...(isActionRemove && { intent: 'danger' })}
        icon={isActionRemove ? iconix.UserTimes : iconix.UserPlus}
        onClose={handleCloseModal}
        title={action}
        visible={visible}
      >
        <ModalStyle />
        <Flex justifyContent="center" flexDirection="column">
          {isSingleDealer ? (
            <>
              <P>
                Clicking {action.toUpperCase()} will{' '}
                {isActionRemove ? 'prevent' : ' again grant'}{' '}
                <span>{user.username}</span> (email {user.email}){' '}
                {isActionRemove ? 'from accessing' : 'access to'}{' '}
                <span>{dealers[0]?.name}</span>.
              </P>
              {isActionRemove && (
                <P>
                  They will still have access to other locations where they have
                  been added.
                </P>
              )}
            </>
          ) : (
            <>
              <P>
                Clicking {action.toUpperCase()} will{' '}
                {isActionRemove ? 'prevent' : 'again grant'}{' '}
                <span>{user.username}</span> (email {user.email}){' '}
                {isActionRemove ? 'from accessing' : 'access to'} these
                locations:
              </P>
              <ul>
                {dealers.map((dealer, _index) => (
                  <li key={dealer.id}>
                    {dealer.id} {dealer.name}
                  </li>
                ))}
              </ul>
              {isActionRemove && (
                <P>
                  They will still have access to other locations where they have
                  been added.
                </P>
              )}
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
};

ActionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  actionType: PropTypes.oneOf(['remove', 'restore', '']).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default ActionModal;

// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AssociatedLocationsTable after component have loaded when there is locations renders the locations 1`] = `
.c0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.c4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.c3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.c22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c5 {
  font-family: 'Montserrat',sans-serif;
  margin: 0;
  overflow: visible;
  -webkit-appearance: button;
}

.c5::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.c6 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0;
}

.c6:disabled:disabled {
  background-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c16 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0 0.625rem;
}

.c16:disabled:disabled {
  background-color: rgba(37,38,46,0.15);
  border-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c7 {
  line-height: 0;
  margin-right: 0.3125rem;
}

.c17 {
  line-height: 0;
}

.c30 {
  line-height: 0;
  margin-left: 0.3125rem;
}

.c12 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #c5cad3;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c12 > * {
  visibility: hidden;
}

.c10 {
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  position: relative;
}

.c10 input {
  -webkit-clip: rect(1px,1px,1px,1px);
  clip: rect(1px,1px,1px,1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.c10 input[type='checkbox'],
.c10 input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.c10 input:focus + label .c11 {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c10 input:checked + label .c11 > * {
  visibility: visible;
}

.c10 label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c10 label:hover .c11 {
  border-color: #61b0ff;
}

.c10 .c11 > * {
  color: #1c6ee6;
}

.c10 label span {
  line-height: 1.4;
  margin-left: 0.625rem;
}

.c10 label + * {
  margin-top: 0.625rem;
}

.c10 .c11 {
  width: 1.125rem;
  height: 1.125rem;
}

.c10 span {
  font-size: 0.75rem;
}

.c10 input:disabled + label,
.c10 input[readonly] + label {
  cursor: not-allowed;
}

.c10 input:disabled + label .c11,
.c10 input[readonly] + label .c11 {
  background: rgba(241,242,248,0.5);
  border-color: #c5cad3;
}

.c10 input:disabled + label .c11 > *,
.c10 input[readonly] + label .c11 > * {
  color: rgba(37,38,46,0.4);
}

.c25 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
  color: #fff;
  background-color: #1c6ee6;
}

.c25:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c28 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
}

.c28:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c26 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29:focus:not(:active) {
  color: #1c6ee6;
  background-color: transparent;
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c29:hover:not(:active) {
  color: #1c6ee6;
  background-color: rgba(28,110,230,0.15);
}

.c27 {
  -webkit-appearance: none;
}

.c24 {
  list-style: none;
}

.c23 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c23 > * {
  margin-right: 0.125rem;
}

.c21 {
  line-height: 1.4;
}

.c1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #737482;
  font-size: 0.75rem;
  padding: 0.625rem 1.25rem;
  width: 100%;
  background-color: #e3e8f1;
}

.c8 {
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
}

.c8 table {
  border-spacing: 0;
  border-collapse: collapse;
  height: 100%;
  text-align: left;
  width: 100%;
  background-color: #61b0ff;
}

.c8 table thead {
  height: 100%;
}

.c8 table thead tr {
  height: 100%;
}

.c8 table thead tr th {
  background-color: #fff;
  box-shadow: inset 0px -2px #c7cbd4;
  height: 100%;
  padding-bottom: 0;
  padding-top: 0;
  vertical-align: top;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.c8 table thead tr th.left:after,
.c8 table thead tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table thead tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th > div {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
  text-transform: uppercase;
}

.c8 table thead tr th > div > .fluid {
  margin: -0.9375rem -0.625rem;
  padding: 0.9375rem 0.625rem;
}

.c8 table thead tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table thead tr th:first-of-type > div > .fluid {
  margin-left: -1.25rem;
  padding-left: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table thead tr th:last-of-type > div > .fluid {
  margin-right: -1.25rem;
  padding-right: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th.left,
.c8 table thead tr th.right {
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
}

.c8 table thead tr th.handle-container {
  width: 1px;
}

.c8 table thead tr th.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table td.handle-container path:hover {
  fill: #61b0ff;
}

.c8 table td.handle-container *:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table td.handle-container *:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table td.handle-container *:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: 1px solid #c7cbd4;
}

.c8 table tbody tr:first-of-type td,
.c8 table tbody tr:first-of-type th {
  border-top: none;
}

.c8 table tbody tr {
  background-color: #fff;
}

.c8 table tbody tr:active {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:active:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:active:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus-visible {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus-visible:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus-visible:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr td,
.c8 table tbody tr th {
  border-top: 1px solid #c7cbd4;
}

.c8 table tbody tr td {
  background-color: #fff;
  background-clip: padding-box;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.c8 table tbody tr td:first-of-type > div,
.c8 table tbody tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table tbody tr td:last-of-type > div,
.c8 table tbody tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table tbody tr th.handle-container,
.c8 table tbody tr td.handle-container {
  width: 1px;
}

.c8 table tbody tr th.handle-container:active,
.c8 table tbody tr td.handle-container:active {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.c8 table tbody tr th.handle-container div,
.c8 table tbody tr td.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
}

.c8 table tbody tr th.left,
.c8 table tbody tr td.left,
.c8 table tbody tr th.right,
.c8 table tbody tr td.right {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after,
.c8 table tbody tr td.right:after,
.c8 table tbody tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr td:not(.right) + td.right:after,
.c8 table tbody tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr.group-header-row > th {
  background-color: #fff;
  border-bottom: 2px solid #dedee0;
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: none;
}

.c8 table thead tr th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr:nth-of-type(even) td {
  background-color: #f8f9fc;
}

.c8 table tbody tr:nth-of-type(odd) td {
  background-color: #fff;
}

.c8 table tbody tr.group-header-row th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr.group-data-row-even {
  background-color: #fff;
}

.c8 table tbody tr.group-data-row-odd {
  background-color: #f8f9fc;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.c2 {
  padding: 0.3125rem 1.25rem;
  border-bottom: 1px solid rgba(37,38,46,0.15);
}

.c18 {
  border-top: 2px solid rgba(37,38,46,0.15);
}

.c15 {
  color: #1c6ee6;
}

.c13 {
  font-size: 0.75rem;
  font-weight: 600;
  background: transparent;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  width: calc(100% + 20px);
}

.c13 > div {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c13 > div > span {
  margin-right: 5px;
}

.c13:active,
.c13:focus {
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  border-color: #1c6ee6;
  outline: none;
}

.c13:hover {
  background-color: #61b0ff;
  color: #fff;
}

.c13:hover .c14 {
  color: #fff;
}

.c9 {
  position: relative;
  z-index: 0;
}

<div>
  <div
    class="c0"
  >
    <div
      style="flex: 1;"
    >
      <div
        class="c1 c2"
        kind="primary"
      >
        <div
          class="c3"
        >
          <div
            class="c4"
          >
            <span
              style="margin-right: 10px;"
            >
              60
               
              Locations
            </span>
          </div>
          <button
            class="c5 c6"
            disabled=""
            type="button"
          >
            <span
              class="c7"
            >
              <svg
                color="currentColor"
                fill="none"
                focusable="false"
                height="12"
                viewBox="0 0 16 16"
                width="12"
              >
                <path
                  clip-rule="evenodd"
                  d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
                <path
                  d="M15.7 11.7L14.4 13l1.3 1.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L13 14.4l-1.3 1.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.3-1.3-1.3-1.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3 1.3-1.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Remove Access to selected locations
          </button>
        </div>
      </div>
      <div
        class="c8 c9"
        kind="primary"
      >
        <table>
          <thead>
            <tr>
              <th
                id="selected"
                scope="col"
              >
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-20"
                      type="checkbox"
                    />
                    <label
                      for="input-20"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </th>
              <th
                id="location"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-22"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        Location Name
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-22"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="id"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-23"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        ID
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-23"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="city"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-24"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        City State
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-24"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="roles"
                scope="col"
              >
                <div>
                  <span>
                    Permissions
                  </span>
                </div>
              </th>
              <th
                id="actions"
                scope="col"
              >
                <div>
                  <span>
                    Actions
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-25"
                      type="checkbox"
                    />
                    <label
                      for="input-25"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div
                    style="display: inline-flex; align-items: center; flex-wrap: wrap; gap: 10px;"
                  >
                    <a
                      style="text-decoration: underline; font-size: 12px; white-space: nowrap;"
                    >
                      Global Fleet of Volvo
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <a
                    href="/dealers/edit/9999"
                    style="text-decoration: underline; font-size: 12px;"
                  />
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Greensboro NC
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Manager, Technician
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <div
                    class="c4"
                  >
                    <button
                      aria-controls=""
                      aria-expanded="false"
                      aria-haspopup="dialog"
                      aria-label="Edit"
                      class="c5 c16"
                      kind="secondary"
                      type="button"
                    >
                      <span
                        class="c17"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M14.938 1.125c-1.5-1.5-4-1.5-5.5 0l-1 1c-.4.4-.4 1.1 0 1.5l3.3 3.3-6.1 6.1-3.3.7.7-3.3 3.8-3.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-4 4c-.2.2-.3.3-.4.5l-1 5.1c-.1.3 0 .7.3.9.2.2.4.3.7.3h.2l5.1-1c.2 0 .4-.1.5-.3l7.1-7.1 1-1c1.5-1.5 1.5-4 0-5.5zm-4.1 1.5c.7-.7 1.9-.7 2.6 0 .7.7.7 1.9 0 2.6l-.3.3-2.6-2.6.3-.3z"
                            fill="currentColor"
                            fill-rule="nonzero"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      class="c5 c16"
                      kind="secondary"
                      style="margin-left: 5px;"
                      type="button"
                    >
                      <span
                        class="c7"
                      >
                        <svg
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M15.7 11.7L14.4 13l1.3 1.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L13 14.4l-1.3 1.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.3-1.3-1.3-1.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3 1.3-1.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      Remove Access
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="c1 c18"
        kind="primary"
      >
        <div
          class="c19"
        >
          <div
            class="c4"
          >
            1
             - 
            20
             of 
            60
             
            Locations
          </div>
          <nav
            aria-label="Pagination"
            class="c4"
            role="navigation"
          >
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Previous"
                class="c5 c6 c21"
                disabled=""
                type="button"
              >
                <span
                  class="c7"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M1.1 7.6c-.1.3-.1.5 0 .8.1.1.1.2.2.3l6 6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L4.4 9H14c.6 0 1-.4 1-1s-.4-1-1-1H4.4l4.3-4.3c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-6 6c-.1.1-.2.2-.2.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                Previous
              </button>
            </div>
            <ul
              class="c22 c23"
            >
              <li
                class="c24"
              >
                <div
                  aria-current="true"
                  aria-label="Current page, page 1"
                  class="c5 c25 c26 c27"
                >
                  1
                </div>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 2"
                  class="c5 c28 c29"
                >
                  2
                </button>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 3"
                  class="c5 c28 c29"
                >
                  3
                </button>
              </li>
            </ul>
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Go to next page, page 2"
                class="c5 c6 c21"
                type="button"
              >
                Next
                <span
                  class="c30"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M14.9 8.4c.1-.2.1-.5 0-.8-.1-.1-.1-.2-.2-.3l-6-6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L11.6 7H2c-.6 0-1 .4-1 1s.4 1 1 1h9.6l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l6-6c.1-.1.2-.2.2-.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`AssociatedLocationsTable after component have loaded when there is locations when some locations are selected enables the action button 1`] = `
.c0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.c4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.c3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.c22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c5 {
  font-family: 'Montserrat',sans-serif;
  margin: 0;
  overflow: visible;
  -webkit-appearance: button;
}

.c5::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.c6 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0;
}

.c6:disabled:disabled {
  background-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c16 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0 0.625rem;
}

.c16:disabled:disabled {
  background-color: rgba(37,38,46,0.15);
  border-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c7 {
  line-height: 0;
  margin-right: 0.3125rem;
}

.c17 {
  line-height: 0;
}

.c30 {
  line-height: 0;
  margin-left: 0.3125rem;
}

.c12 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #c5cad3;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c12 > * {
  visibility: hidden;
}

.c10 {
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  position: relative;
}

.c10 input {
  -webkit-clip: rect(1px,1px,1px,1px);
  clip: rect(1px,1px,1px,1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.c10 input[type='checkbox'],
.c10 input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.c10 input:focus + label .c11 {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c10 input:checked + label .c11 > * {
  visibility: visible;
}

.c10 label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c10 label:hover .c11 {
  border-color: #61b0ff;
}

.c10 .c11 > * {
  color: #1c6ee6;
}

.c10 label span {
  line-height: 1.4;
  margin-left: 0.625rem;
}

.c10 label + * {
  margin-top: 0.625rem;
}

.c10 .c11 {
  width: 1.125rem;
  height: 1.125rem;
}

.c10 span {
  font-size: 0.75rem;
}

.c10 input:disabled + label,
.c10 input[readonly] + label {
  cursor: not-allowed;
}

.c10 input:disabled + label .c11,
.c10 input[readonly] + label .c11 {
  background: rgba(241,242,248,0.5);
  border-color: #c5cad3;
}

.c10 input:disabled + label .c11 > *,
.c10 input[readonly] + label .c11 > * {
  color: rgba(37,38,46,0.4);
}

.c25 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
  color: #fff;
  background-color: #1c6ee6;
}

.c25:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c28 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
}

.c28:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c26 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29:focus:not(:active) {
  color: #1c6ee6;
  background-color: transparent;
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c29:hover:not(:active) {
  color: #1c6ee6;
  background-color: rgba(28,110,230,0.15);
}

.c27 {
  -webkit-appearance: none;
}

.c24 {
  list-style: none;
}

.c23 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c23 > * {
  margin-right: 0.125rem;
}

.c21 {
  line-height: 1.4;
}

.c1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #737482;
  font-size: 0.75rem;
  padding: 0.625rem 1.25rem;
  width: 100%;
  background-color: #e3e8f1;
}

.c8 {
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
}

.c8 table {
  border-spacing: 0;
  border-collapse: collapse;
  height: 100%;
  text-align: left;
  width: 100%;
  background-color: #61b0ff;
}

.c8 table thead {
  height: 100%;
}

.c8 table thead tr {
  height: 100%;
}

.c8 table thead tr th {
  background-color: #fff;
  box-shadow: inset 0px -2px #c7cbd4;
  height: 100%;
  padding-bottom: 0;
  padding-top: 0;
  vertical-align: top;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.c8 table thead tr th.left:after,
.c8 table thead tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table thead tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th > div {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
  text-transform: uppercase;
}

.c8 table thead tr th > div > .fluid {
  margin: -0.9375rem -0.625rem;
  padding: 0.9375rem 0.625rem;
}

.c8 table thead tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table thead tr th:first-of-type > div > .fluid {
  margin-left: -1.25rem;
  padding-left: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table thead tr th:last-of-type > div > .fluid {
  margin-right: -1.25rem;
  padding-right: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th.left,
.c8 table thead tr th.right {
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
}

.c8 table thead tr th.handle-container {
  width: 1px;
}

.c8 table thead tr th.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table td.handle-container path:hover {
  fill: #61b0ff;
}

.c8 table td.handle-container *:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table td.handle-container *:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table td.handle-container *:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: 1px solid #c7cbd4;
}

.c8 table tbody tr:first-of-type td,
.c8 table tbody tr:first-of-type th {
  border-top: none;
}

.c8 table tbody tr {
  background-color: #fff;
}

.c8 table tbody tr:active {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:active:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:active:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus-visible {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus-visible:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus-visible:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr td,
.c8 table tbody tr th {
  border-top: 1px solid #c7cbd4;
}

.c8 table tbody tr td {
  background-color: #fff;
  background-clip: padding-box;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.c8 table tbody tr td:first-of-type > div,
.c8 table tbody tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table tbody tr td:last-of-type > div,
.c8 table tbody tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table tbody tr th.handle-container,
.c8 table tbody tr td.handle-container {
  width: 1px;
}

.c8 table tbody tr th.handle-container:active,
.c8 table tbody tr td.handle-container:active {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.c8 table tbody tr th.handle-container div,
.c8 table tbody tr td.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
}

.c8 table tbody tr th.left,
.c8 table tbody tr td.left,
.c8 table tbody tr th.right,
.c8 table tbody tr td.right {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after,
.c8 table tbody tr td.right:after,
.c8 table tbody tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr td:not(.right) + td.right:after,
.c8 table tbody tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr.group-header-row > th {
  background-color: #fff;
  border-bottom: 2px solid #dedee0;
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: none;
}

.c8 table thead tr th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr:nth-of-type(even) td {
  background-color: #f8f9fc;
}

.c8 table tbody tr:nth-of-type(odd) td {
  background-color: #fff;
}

.c8 table tbody tr.group-header-row th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr.group-data-row-even {
  background-color: #fff;
}

.c8 table tbody tr.group-data-row-odd {
  background-color: #f8f9fc;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.c2 {
  padding: 0.3125rem 1.25rem;
  border-bottom: 1px solid rgba(37,38,46,0.15);
}

.c18 {
  border-top: 2px solid rgba(37,38,46,0.15);
}

.c15 {
  color: #1c6ee6;
}

.c13 {
  font-size: 0.75rem;
  font-weight: 600;
  background: transparent;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  width: calc(100% + 20px);
}

.c13 > div {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c13 > div > span {
  margin-right: 5px;
}

.c13:active,
.c13:focus {
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  border-color: #1c6ee6;
  outline: none;
}

.c13:hover {
  background-color: #61b0ff;
  color: #fff;
}

.c13:hover .c14 {
  color: #fff;
}

.c9 {
  position: relative;
  z-index: 0;
}

<div>
  <div
    class="c0"
  >
    <div
      style="flex: 1;"
    >
      <div
        class="c1 c2"
        kind="primary"
      >
        <div
          class="c3"
        >
          <div
            class="c4"
          >
            <span
              style="margin-right: 5px;"
            >
              Selected 1 of 
            </span>
            <span
              style="margin-right: 10px;"
            >
              60
               
              Locations
            </span>
          </div>
          <button
            class="c5 c6"
            type="button"
          >
            <span
              class="c7"
            >
              <svg
                color="currentColor"
                fill="none"
                focusable="false"
                height="12"
                viewBox="0 0 16 16"
                width="12"
              >
                <path
                  clip-rule="evenodd"
                  d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
                <path
                  d="M15.7 11.7L14.4 13l1.3 1.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L13 14.4l-1.3 1.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.3-1.3-1.3-1.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3 1.3-1.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Remove Access to selected locations
          </button>
        </div>
      </div>
      <div
        class="c8 c9"
        kind="primary"
      >
        <table>
          <thead>
            <tr>
              <th
                id="selected"
                scope="col"
              >
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-29"
                      type="checkbox"
                    />
                    <label
                      for="input-29"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </th>
              <th
                id="location"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-31"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        Location Name
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-31"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="id"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-32"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        ID
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-32"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="city"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-33"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        City State
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-33"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="roles"
                scope="col"
              >
                <div>
                  <span>
                    Permissions
                  </span>
                </div>
              </th>
              <th
                id="actions"
                scope="col"
              >
                <div>
                  <span>
                    Actions
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-34"
                      type="checkbox"
                    />
                    <label
                      for="input-34"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div
                    style="display: inline-flex; align-items: center; flex-wrap: wrap; gap: 10px;"
                  >
                    <a
                      style="text-decoration: underline; font-size: 12px; white-space: nowrap;"
                    >
                      Global Fleet of Volvo
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <a
                    href="/dealers/edit/9999"
                    style="text-decoration: underline; font-size: 12px;"
                  />
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Greensboro NC
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Manager, Technician
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <div
                    class="c4"
                  >
                    <button
                      aria-controls=""
                      aria-expanded="false"
                      aria-haspopup="dialog"
                      aria-label="Edit"
                      class="c5 c16"
                      kind="secondary"
                      type="button"
                    >
                      <span
                        class="c17"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M14.938 1.125c-1.5-1.5-4-1.5-5.5 0l-1 1c-.4.4-.4 1.1 0 1.5l3.3 3.3-6.1 6.1-3.3.7.7-3.3 3.8-3.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-4 4c-.2.2-.3.3-.4.5l-1 5.1c-.1.3 0 .7.3.9.2.2.4.3.7.3h.2l5.1-1c.2 0 .4-.1.5-.3l7.1-7.1 1-1c1.5-1.5 1.5-4 0-5.5zm-4.1 1.5c.7-.7 1.9-.7 2.6 0 .7.7.7 1.9 0 2.6l-.3.3-2.6-2.6.3-.3z"
                            fill="currentColor"
                            fill-rule="nonzero"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      class="c5 c16"
                      kind="secondary"
                      style="margin-left: 5px;"
                      type="button"
                    >
                      <span
                        class="c7"
                      >
                        <svg
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M15.7 11.7L14.4 13l1.3 1.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L13 14.4l-1.3 1.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.3-1.3-1.3-1.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.3 1.3 1.3-1.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      Remove Access
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="c1 c18"
        kind="primary"
      >
        <div
          class="c19"
        >
          <div
            class="c4"
          >
            1
             - 
            20
             of 
            60
             
            Locations
          </div>
          <nav
            aria-label="Pagination"
            class="c4"
            role="navigation"
          >
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Previous"
                class="c5 c6 c21"
                disabled=""
                type="button"
              >
                <span
                  class="c7"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M1.1 7.6c-.1.3-.1.5 0 .8.1.1.1.2.2.3l6 6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L4.4 9H14c.6 0 1-.4 1-1s-.4-1-1-1H4.4l4.3-4.3c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-6 6c-.1.1-.2.2-.2.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                Previous
              </button>
            </div>
            <ul
              class="c22 c23"
            >
              <li
                class="c24"
              >
                <div
                  aria-current="true"
                  aria-label="Current page, page 1"
                  class="c5 c25 c26 c27"
                >
                  1
                </div>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 2"
                  class="c5 c28 c29"
                >
                  2
                </button>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 3"
                  class="c5 c28 c29"
                >
                  3
                </button>
              </li>
            </ul>
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Go to next page, page 2"
                class="c5 c6 c21"
                type="button"
              >
                Next
                <span
                  class="c30"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M14.9 8.4c.1-.2.1-.5 0-.8-.1-.1-.1-.2-.2-.3l-6-6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L11.6 7H2c-.6 0-1 .4-1 1s.4 1 1 1h9.6l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l6-6c.1-.1.2-.2.2-.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`AssociatedLocationsTable after component have loaded when there is no locations renders the empty state component 1`] = `
.c0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.c2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c7 {
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #25262e;
  font-size: 1rem;
  line-height: 120%;
}

.c9 {
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #737482;
  font-size: 0.875rem;
  line-height: 140%;
  font-size: 0.75rem;
}

.c5 {
  line-height: 0;
  margin-right: 0.3125rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #9c9fab;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.c3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  line-height: 1.4;
  text-align: left;
  max-width: 240px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.c3 .c4 {
  margin-right: 10px;
  width: 36px;
  height: 36px;
}

.c3 .c4 {
  margin-right: 0;
}

.c3 .c8 {
  margin-top: 5px;
}

.c3 .c6 {
  margin-top: 10px;
}

.c1 {
  background-color: #f1f2f8;
  padding: 20px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c1 .empty-state-message {
  font-size: 12px;
}

<div>
  <div
    class="c0"
  >
    <div
      class="c1"
    >
      <div
        class="c2"
      >
        <div
          class="c3"
        >
          <div
            aria-hidden="true"
            class="c4 c5"
            color="#9c9fab"
          >
            <svg
              color="#fff"
              fill="none"
              focusable="false"
              height="16"
              viewBox="0 0 16 16"
              width="16"
            >
              <path
                d="M13 15c0 .6.4 1 1 1s1-.4 1-1V1c0-.6-.4-1-1-1H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1s1-.4 1-1V2h10v13z"
                fill="currentColor"
              />
              <path
                d="M4 6c0 .6.4 1 1 1s1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v1zM8 7c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1s1 .4 1 1v1c0 .6-.4 1-1 1zM10 6c0 .6.4 1 1 1s1-.4 1-1V5c0-.6-.4-1-1-1s-1 .4-1 1v1zM5 11c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1s1 .4 1 1v1c0 .6-.4 1-1 1zM7 10c0 .6.4 1 1 1s1-.4 1-1V9c0-.6-.4-1-1-1s-1 .4-1 1v1zM11 11c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1s1 .4 1 1v1c0 .6-.4 1-1 1zM7 15c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1v2z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div>
            <h4
              class="c6 c7"
              color="charcoal"
            >
              No Locations
            </h4>
            <p
              class="c8 c9"
              color="alaskanHusky"
            >
              <span
                class="empty-state-message"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`AssociatedLocationsTable when associationsRemoved is true renders the locations 1`] = `
.c0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.c4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.c3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.c22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c5 {
  font-family: 'Montserrat',sans-serif;
  margin: 0;
  overflow: visible;
  -webkit-appearance: button;
}

.c5::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.c6 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0;
}

.c6:disabled:disabled {
  background-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c16 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  outline: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0 0.625rem;
}

.c16:disabled:disabled {
  background-color: rgba(37,38,46,0.15);
  border-color: transparent;
  color: rgba(37,38,46,0.4);
  cursor: not-allowed;
}

.c7 {
  line-height: 0;
  margin-right: 0.3125rem;
}

.c17 {
  line-height: 0;
}

.c30 {
  line-height: 0;
  margin-left: 0.3125rem;
}

.c12 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #c5cad3;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c12 > * {
  visibility: hidden;
}

.c10 {
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  position: relative;
}

.c10 input {
  -webkit-clip: rect(1px,1px,1px,1px);
  clip: rect(1px,1px,1px,1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.c10 input[type='checkbox'],
.c10 input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.c10 input:focus + label .c11 {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c10 input:checked + label .c11 > * {
  visibility: visible;
}

.c10 label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c10 label:hover .c11 {
  border-color: #61b0ff;
}

.c10 .c11 > * {
  color: #1c6ee6;
}

.c10 label span {
  line-height: 1.4;
  margin-left: 0.625rem;
}

.c10 label + * {
  margin-top: 0.625rem;
}

.c10 .c11 {
  width: 1.125rem;
  height: 1.125rem;
}

.c10 span {
  font-size: 0.75rem;
}

.c10 input:disabled + label,
.c10 input[readonly] + label {
  cursor: not-allowed;
}

.c10 input:disabled + label .c11,
.c10 input[readonly] + label .c11 {
  background: rgba(241,242,248,0.5);
  border-color: #c5cad3;
}

.c10 input:disabled + label .c11 > *,
.c10 input[readonly] + label .c11 > * {
  color: rgba(37,38,46,0.4);
}

.c25 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
  color: #fff;
  background-color: #1c6ee6;
}

.c25:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c28 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-transform: uppercase;
  color: #1c6ee6;
}

.c28:active {
  color: #fff;
  background-color: #1c6ee6;
}

.c26 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29 {
  border-radius: 50%;
  width: 1.625rem;
  min-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
}

.c29:focus:not(:active) {
  color: #1c6ee6;
  background-color: transparent;
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c29:hover:not(:active) {
  color: #1c6ee6;
  background-color: rgba(28,110,230,0.15);
}

.c27 {
  -webkit-appearance: none;
}

.c24 {
  list-style: none;
}

.c23 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c23 > * {
  margin-right: 0.125rem;
}

.c21 {
  line-height: 1.4;
}

.c1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #737482;
  font-size: 0.75rem;
  padding: 0.625rem 1.25rem;
  width: 100%;
  background-color: #e3e8f1;
}

.c8 {
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
}

.c8 table {
  border-spacing: 0;
  border-collapse: collapse;
  height: 100%;
  text-align: left;
  width: 100%;
  background-color: #61b0ff;
}

.c8 table thead {
  height: 100%;
}

.c8 table thead tr {
  height: 100%;
}

.c8 table thead tr th {
  background-color: #fff;
  box-shadow: inset 0px -2px #c7cbd4;
  height: 100%;
  padding-bottom: 0;
  padding-top: 0;
  vertical-align: top;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.c8 table thead tr th.left:after,
.c8 table thead tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table thead tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table thead tr th > div {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
  text-transform: uppercase;
}

.c8 table thead tr th > div > .fluid {
  margin: -0.9375rem -0.625rem;
  padding: 0.9375rem 0.625rem;
}

.c8 table thead tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table thead tr th:first-of-type > div > .fluid {
  margin-left: -1.25rem;
  padding-left: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table thead tr th:last-of-type > div > .fluid {
  margin-right: -1.25rem;
  padding-right: 1.25rem;
  width: calc(100% + 1.875rem);
}

.c8 table thead tr th.left,
.c8 table thead tr th.right {
  z-index: 4;
  position: -webkit-sticky;
  position: sticky;
}

.c8 table thead tr th.handle-container {
  width: 1px;
}

.c8 table thead tr th.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table td.handle-container path:hover {
  fill: #61b0ff;
}

.c8 table td.handle-container *:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table td.handle-container *:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table td.handle-container *:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: 1px solid #c7cbd4;
}

.c8 table tbody tr:first-of-type td,
.c8 table tbody tr:first-of-type th {
  border-top: none;
}

.c8 table tbody tr {
  background-color: #fff;
}

.c8 table tbody tr:active {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:active:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:active:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr:focus-visible {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  opacity: 0.8;
  outline: #1c6ee6 solid 1px;
  border-radius: 2px;
}

.c8 table tbody tr:focus-visible:active {
  border-color: #1c6ee6;
  box-shadow: 0px 1px 4px rgba(37,38,46,0.4);
  opacity: 0.8;
}

.c8 table tbody tr:focus-visible:focus:not(:active) {
  border-color: #1c6ee6;
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
}

.c8 table tbody tr td,
.c8 table tbody tr th {
  border-top: 1px solid #c7cbd4;
}

.c8 table tbody tr td {
  background-color: #fff;
  background-clip: padding-box;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.c8 table tbody tr td:first-of-type > div,
.c8 table tbody tr th:first-of-type > div {
  padding-left: 1.25rem;
}

.c8 table tbody tr td:last-of-type > div,
.c8 table tbody tr th:last-of-type > div {
  padding-right: 1.25rem;
}

.c8 table tbody tr th.handle-container,
.c8 table tbody tr td.handle-container {
  width: 1px;
}

.c8 table tbody tr th.handle-container:active,
.c8 table tbody tr td.handle-container:active {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.c8 table tbody tr th.handle-container div,
.c8 table tbody tr td.handle-container div {
  padding-left: 0;
  padding-right: 0;
}

.c8 table tbody tr th > div {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 0.9375rem 0.625rem;
}

.c8 table tbody tr th.left,
.c8 table tbody tr td.left,
.c8 table tbody tr th.right,
.c8 table tbody tr td.right {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after,
.c8 table tbody tr td.right:after,
.c8 table tbody tr th.right:after {
  content: ' ';
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
}

.c8 table tbody tr td.left:after,
.c8 table tbody tr th.left:after {
  right: 0;
  box-shadow: 1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr td:not(.right) + td.right:after,
.c8 table tbody tr th:not(.right) + th.right:after {
  left: -1px;
  box-shadow: inset -1px 0px #c7cbd4;
  width: 1px;
}

.c8 table tbody tr.group-header-row > th {
  background-color: #fff;
  border-bottom: 2px solid #dedee0;
}

.c8 table tbody tr:last-of-type td,
.c8 table tbody tr:last-of-type th {
  border-bottom: none;
}

.c8 table thead tr th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr:nth-of-type(even) td {
  background-color: #f8f9fc;
}

.c8 table tbody tr:nth-of-type(odd) td {
  background-color: #fff;
}

.c8 table tbody tr.group-header-row th {
  background-color: #e3e8f1;
  border-bottom-color: #c7cbd4;
}

.c8 table tbody tr.group-data-row-even {
  background-color: #fff;
}

.c8 table tbody tr.group-data-row-odd {
  background-color: #f8f9fc;
}

.c8 table tbody tr td > div,
.c8 table tbody tr th > div {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.c2 {
  padding: 0.3125rem 1.25rem;
  border-bottom: 1px solid rgba(37,38,46,0.15);
}

.c18 {
  border-top: 2px solid rgba(37,38,46,0.15);
}

.c15 {
  color: #1c6ee6;
}

.c13 {
  font-size: 0.75rem;
  font-weight: 600;
  background: transparent;
  border: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;
  width: calc(100% + 20px);
}

.c13 > div {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c13 > div > span {
  margin-right: 5px;
}

.c13:active,
.c13:focus {
  box-shadow: inset 0 0 0 2px rgba(28,110,230,0.4);
  border-color: #1c6ee6;
  outline: none;
}

.c13:hover {
  background-color: #61b0ff;
  color: #fff;
}

.c13:hover .c14 {
  color: #fff;
}

.c9 {
  position: relative;
  z-index: 0;
}

<div>
  <div
    class="c0"
  >
    <div
      style="flex: 1;"
    >
      <div
        class="c1 c2"
        kind="primary"
      >
        <div
          class="c3"
        >
          <div
            class="c4"
          >
            <span
              style="margin-right: 10px;"
            >
              60
               
              Locations
            </span>
          </div>
          <button
            class="c5 c6"
            disabled=""
            type="button"
          >
            <span
              class="c7"
            >
              <svg
                color="currentColor"
                fill="none"
                focusable="false"
                height="12"
                viewBox="0 0 16 16"
                width="12"
              >
                <path
                  clip-rule="evenodd"
                  d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
                <path
                  d="M14 12h1c.6 0 1 .4 1 1s-.4 1-1 1h-1v1c0 .6-.4 1-1 1s-1-.4-1-1v-1h-1c-.6 0-1-.4-1-1s.4-1 1-1h1v-1c0-.6.4-1 1-1s1 .4 1 1v1z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Restore Access to selected locations
          </button>
        </div>
      </div>
      <div
        class="c8 c9"
        kind="primary"
      >
        <table>
          <thead>
            <tr>
              <th
                id="selected"
                scope="col"
              >
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-48"
                      type="checkbox"
                    />
                    <label
                      for="input-48"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </th>
              <th
                id="location"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-50"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        Location Name
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-50"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="id"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-51"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        ID
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-51"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="city"
                scope="col"
              >
                <div>
                  <button
                    aria-describedby="table-sorting-icon-52"
                    class="c5 c13 fluid"
                    kind="primary"
                    type="button"
                  >
                    <div>
                      <span>
                        City State
                      </span>
                      <span
                        aria-label="unsorted"
                        class="c14 c15"
                        id="table-sorting-icon-52"
                        role="img"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M11 12.586V1a1 1 0 012 0v11.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L11 12.586zM3 3.414L1.707 4.707A1 1 0 01.293 3.293l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L5 3.414V15a1 1 0 01-2 0V3.414z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>
                </div>
              </th>
              <th
                id="roles"
                scope="col"
              >
                <div>
                  <span>
                    Permissions
                  </span>
                </div>
              </th>
              <th
                id="removed"
                scope="col"
              >
                <div>
                  <span>
                    Removed
                  </span>
                </div>
              </th>
              <th
                id="actions"
                scope="col"
              >
                <div>
                  <span>
                    Actions
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    class="c10"
                  >
                    <input
                      id="input-53"
                      type="checkbox"
                    />
                    <label
                      for="input-53"
                    >
                      <div
                        class="c11 c12"
                      >
                        <svg
                          aria-hidden="true"
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M6.5 14.5c-.2 0-.4-.1-.6-.2l-5-4c-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2L6.3 12l7.4-10.2c.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4l-8 11c-.2.2-.4.4-.7.4 0 .1-.1.1-.1.1z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div
                    style="display: inline-flex; align-items: center; flex-wrap: wrap; gap: 10px;"
                  >
                    <a
                      style="text-decoration: underline; font-size: 12px; white-space: nowrap;"
                    >
                      Global Fleet of Volvo
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <a
                    href="/dealers/edit/9999"
                    style="text-decoration: underline; font-size: 12px;"
                  />
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Greensboro NC
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>
                    Manager, Technician
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>
                    01/22/2025 10:51 AM EST
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <div
                    class="c4"
                  >
                    <button
                      aria-controls=""
                      aria-expanded="false"
                      aria-haspopup="dialog"
                      aria-label="Edit"
                      class="c5 c16"
                      kind="secondary"
                      type="button"
                    >
                      <span
                        class="c17"
                      >
                        <svg
                          color="currentColor"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            d="M14.938 1.125c-1.5-1.5-4-1.5-5.5 0l-1 1c-.4.4-.4 1.1 0 1.5l3.3 3.3-6.1 6.1-3.3.7.7-3.3 3.8-3.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-4 4c-.2.2-.3.3-.4.5l-1 5.1c-.1.3 0 .7.3.9.2.2.4.3.7.3h.2l5.1-1c.2 0 .4-.1.5-.3l7.1-7.1 1-1c1.5-1.5 1.5-4 0-5.5zm-4.1 1.5c.7-.7 1.9-.7 2.6 0 .7.7.7 1.9 0 2.6l-.3.3-2.6-2.6.3-.3z"
                            fill="currentColor"
                            fill-rule="nonzero"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      class="c5 c16"
                      kind="secondary"
                      style="margin-left: 5px;"
                      type="button"
                    >
                      <span
                        class="c7"
                      >
                        <svg
                          color="currentColor"
                          fill="none"
                          focusable="false"
                          height="12"
                          viewBox="0 0 16 16"
                          width="12"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M13 5c0-2.8-2.2-5-5-5S3 2.2 3 5c0 1.4.6 2.7 1.6 3.6C1.9 9.7 0 12 0 15c0 .6.4 1 1 1s1-.4 1-1c0-2.9 2.5-5 6-5 2.8 0 5-2.2 5-5zM8 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                            fill="currentColor"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M14 12h1c.6 0 1 .4 1 1s-.4 1-1 1h-1v1c0 .6-.4 1-1 1s-1-.4-1-1v-1h-1c-.6 0-1-.4-1-1s.4-1 1-1h1v-1c0-.6.4-1 1-1s1 .4 1 1v1z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      Restore Access
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="c1 c18"
        kind="primary"
      >
        <div
          class="c19"
        >
          <div
            class="c4"
          >
            1
             - 
            20
             of 
            60
             
            Locations
          </div>
          <nav
            aria-label="Pagination"
            class="c4"
            role="navigation"
          >
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Previous"
                class="c5 c6 c21"
                disabled=""
                type="button"
              >
                <span
                  class="c7"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M1.1 7.6c-.1.3-.1.5 0 .8.1.1.1.2.2.3l6 6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L4.4 9H14c.6 0 1-.4 1-1s-.4-1-1-1H4.4l4.3-4.3c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-6 6c-.1.1-.2.2-.2.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                Previous
              </button>
            </div>
            <ul
              class="c22 c23"
            >
              <li
                class="c24"
              >
                <div
                  aria-current="true"
                  aria-label="Current page, page 1"
                  class="c5 c25 c26 c27"
                >
                  1
                </div>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 2"
                  class="c5 c28 c29"
                >
                  2
                </button>
              </li>
              <li
                class="c24"
              >
                <button
                  aria-label="Go to page 3"
                  class="c5 c28 c29"
                >
                  3
                </button>
              </li>
            </ul>
            <div
              class="NextPrevButton__Container-cpoqd2-0 c20"
            >
              <button
                aria-label="Go to next page, page 2"
                class="c5 c6 c21"
                type="button"
              >
                Next
                <span
                  class="c30"
                >
                  <svg
                    color="currentColor"
                    fill="none"
                    focusable="false"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                  >
                    <path
                      d="M14.9 8.4c.1-.2.1-.5 0-.8-.1-.1-.1-.2-.2-.3l-6-6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L11.6 7H2c-.6 0-1 .4-1 1s.4 1 1 1h9.6l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l6-6c.1-.1.2-.2.2-.3z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
`;

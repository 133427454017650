import { Flex, H2, P } from '@decisiv/ui-components';
import PatchedTable from 'components/PatchedTable';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useUsers from '../hooks/useUsers';
import { createSortHandler } from '../utils/sortUtils';
import EmptyState from './EmptyState';
import LoadingState from './LoadingState';
import PaginationFooter from './PaginationFooter';

const RemovedAccess = ({ dealerId }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [page, setPage] = useState(1);

  const {
    users,
    isLoading,
    pagination: { perPage, totalItems, totalPages },
    fetchUsersData,
  } = useUsers({ dealerId, endpoint: 'removed_access' });

  useEffect(() => {
    fetchUsersData({ page, sortBy, sortOrder });
  }, [fetchUsersData, page, sortBy, sortOrder]);

  const onSort = createSortHandler(setSortBy, setSortOrder);

  const columns = [
    {
      DataCell: ({ rowData }) => (
        <Flex alignItems="center">
          <a
            href={`/users/edit/${rowData.id}`}
            style={{
              textDecoration: 'underline',
              fontSize: '12px',
            }}
          >
            {rowData.username}
          </a>
        </Flex>
      ),
      name: 'username',
      sortBy: onSort('username'),
      title: 'USERNAME',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.name}</span>,
      name: 'name',
      sortBy: onSort('fullname'),
      title: 'NAME',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.job_role}</span>,
      name: 'job_role',
      sortBy: onSort('job_role'),
      title: 'JOB ROLE',
    },
    {
      DataCell: ({ rowData }) => (
        <span>
          {rowData.permissions ? rowData.permissions.join(', ') : '—'}
        </span>
      ),
      name: 'permissions',
      title: 'PERMISSIONS',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.last_login_at || '—'}</span>,
      name: 'last_login_at',
      sortBy: onSort('last_login_at'),
      title: 'LAST LOGIN',
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.removed_at || '—'}</span>,
      name: 'removed_at',
      sortBy: onSort('removed_at'),
      title: 'REMOVED',
    },
  ];

  const getRowKey = ({ id }) => id;

  const footer = () => (
    <PaginationFooter
      page={page}
      pageSize={perPage}
      totalItems={totalItems}
      totalPages={totalPages}
      onPageChange={(newPage) => setPage(newPage)}
    />
  );

  const Header = () => (
    <Flex flexDirection="column" marginTop={2} marginBottom={1}>
      <H2>Removed Access</H2>
      <P color="alaskanHusky">
        Users that no longer have access to this specific location.
      </P>
    </Flex>
  );

  if (isLoading) {
    return (
      <>
        <Header />
        <LoadingState />
      </>
    );
  }

  if (users.length === 0) {
    return (
      <>
        <Header />
        <EmptyState
          title="No Users"
          message="Need to remove someone's access to this location? Go to the Current Access list to start."
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <PatchedTable
        columns={columns}
        data={users}
        getRowKey={getRowKey}
        footer={footer}
      />
    </>
  );
};

RemovedAccess.propTypes = {
  dealerId: PropTypes.number.isRequired,
};

export default RemovedAccess;

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import iconix from '@decisiv/iconix';
import isEmpty from 'lodash/isEmpty';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import {
  Button,
  Checkbox,
  Pagination,
  Tag,
  Tooltip,
} from '@decisiv/ui-components';
import Pencil from '@decisiv/iconix/lib/components/Pencil';

import { createSortHandler } from 'components/pages/Users/utils/sortUtils';
import PatchedTable from 'components/PatchedTable';
import EmptyState from 'components/pages/Users/components/EmptyState';
import LoadingState from 'components/pages/Users/components/LoadingState';
import { CssResetStyle } from './styledComponents';
import { t } from '../utils/translations';

function AssociatedLocationsTable({
  associationsRemoved,
  currentDealerId,
  userId,
  onSingleAction,
  onBulkAction,
  refreshTrigger,
}) {
  const [locations, setLocations] = useState([]);
  const [pagination, setPagination] = useState({ page: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const allChecked = locations.every((item) => item.selected);
  const someChecked = locations.some((item) => item.selected);
  const checkedCount = locations.filter((item) => item.selected).length;
  const onSort = createSortHandler(setSortBy, setSortOrder);

  const iconAction = associationsRemoved ? iconix.UserPlus : iconix.UserTimes;
  const textAction = associationsRemoved
    ? t('action_restore_access')
    : t('action_remove_access');
  const textBulkAction = associationsRemoved
    ? t('action_bulk_restore')
    : t('action_bulk_remove');
  const fetchUrl = associationsRemoved
    ? `/users/${userId}/removed_access`
    : `/users/${userId}/active_access`;

  useEffect(() => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({
      page: pagination.page,
    });

    if (sortBy) queryParams.set('sort_by', sortBy);
    if (sortOrder) queryParams.set('sort_order', sortOrder);

    fetch(`${fetchUrl}?${queryParams.toString()}`)
      .then((res) => {
        return res.json();
      })
      // eslint-disable-next-line consistent-return
      .then((data) => {
        setLocations(data.locations);
        setPagination(data.pagination);
        setIsLoading(false);
      });
  }, [fetchUrl, pagination.page, refreshTrigger, sortBy, sortOrder]);

  /** Bulk Selection */
  const checkAll = useCallback((event) => {
    const {
      target: { checked },
    } = event;
    setLocations((previousData) =>
      previousData.map((item) => ({
        ...item,
        selected: checked,
      })),
    );
  }, []);

  const checkOne = useCallback((id, checked) => {
    setLocations((previousData) =>
      previousData.map((item) =>
        item.id === id ? { ...item, selected: checked } : item,
      ),
    );
  }, []);

  const CheckboxHeaderCell = useCallback(
    ({ headerChecked }) => (
      <Checkbox size="small" onChange={checkAll} checked={headerChecked} />
    ),
    [checkAll],
  );

  const CheckboxDataCell = useCallback(
    ({ rowData }) => (
      <Checkbox
        size="small"
        onChange={({ target: { checked } }) => checkOne(rowData.id, checked)}
        checked={rowData.selected || false}
      />
    ),
    [checkOne],
  );
  /** /Bulk Selection */

  const columns = [
    {
      name: 'selected',
      title: '',
      headerChecked: allChecked,
      HeaderCell: CheckboxHeaderCell,
      DataCell: CheckboxDataCell,
    },
    {
      DataCell: ({ rowData }) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <a
            href={rowData.locationDashboardPath}
            style={{
              textDecoration: 'underline',
              fontSize: '12px',
              whiteSpace: 'nowrap',
            }}
          >
            {rowData.name}
          </a>
          {currentDealerId && rowData.id === currentDealerId && (
            <Tag text={t('current')} size="small" color="information" />
          )}
        </div>
      ),
      name: 'location',
      title: t('table_header.location_name'),
      sortBy: onSort('name'),
    },
    {
      DataCell: ({ rowData }) => (
        <a
          href={rowData.editPath}
          style={{
            textDecoration: 'underline',
            fontSize: '12px',
          }}
        >
          {rowData.decisivId}
        </a>
      ),
      name: 'id',
      title: t('table_header.id'),
      sortBy: onSort('decisiv_id'),
    },
    {
      DataCell: ({ rowData }) => <span>{rowData.location}</span>,
      name: 'city',
      title: t('table_header.location'),
      sortBy: onSort('short_address'),
    },
    {
      DataCell: ({ rowData }) => (
        <span>
          {rowData.permissions ? rowData.permissions.join(', ') : '—'}
        </span>
      ),
      name: 'roles',
      title: t('table_header.roles'),
    },
  ];

  if (associationsRemoved) {
    columns.push({
      DataCell: ({ rowData }) => <span>{rowData.removed_at}</span>,
      name: 'removed',
      title: t('table_header.removed'),
    });
  }

  columns.push({
    DataCell: ({ rowData }) => (
      <Flex>
        <Tooltip
          placement="top"
          target={
            <Button
              aria-label="Edit"
              icon={Pencil}
              size="small"
              kind="secondary"
              onClick={() => {
                window.location.href = rowData.userPermissionsEditPath;
              }}
            />
          }
        >
          Edit User&apos;s Permissions at Location
        </Tooltip>
        <Button
          text={textAction}
          icon={iconAction}
          size="small"
          kind="secondary"
          style={{ marginLeft: '5px' }}
          onClick={() => onSingleAction(rowData)}
          {...(!associationsRemoved && { intent: 'danger' })}
        />
      </Flex>
    ),
    getCellKey: ({ id }) => `${id}-actions`,
    name: 'actions',
    title: t('table_header.actions'),
  });

  const PaginationText = () => {
    const currentEntries = (pagination.page - 1) * pagination.per_page + 1;
    const totalEntries = Math.min(
      pagination.page * pagination.per_page,
      pagination.total_entries,
    );

    return (
      <Flex>
        {currentEntries} - {totalEntries} of {pagination.total_entries}{' '}
        {t('locations')}
      </Flex>
    );
  };

  const footer = () => (
    <Flex flex={1} alignItems="center" justifyContent="space-between">
      <PaginationText />
      <Pagination
        onPageChange={(newPage) =>
          setPagination((previousData) => ({ ...previousData, page: newPage }))
        }
        totalPages={pagination.total_pages}
        perPage={pagination.per_page}
        size="small"
        activePage={pagination.page}
      />
    </Flex>
  );

  const ToolbarText = () => {
    return (
      <Flex>
        {checkedCount > 0 && (
          <span
            style={{ marginRight: '5px' }}
          >{`Selected ${checkedCount} of `}</span>
        )}
        <span style={{ marginRight: '10px' }}>
          {pagination.total_entries} {t('locations')}
        </span>
      </Flex>
    );
  };

  const toolbar = () => (
    <Flex flex={1} alignItems="center">
      <ToolbarText />
      <Button
        variant="ghost"
        text={textBulkAction}
        size="small"
        icon={iconAction}
        disabled={!someChecked}
        onClick={() => onBulkAction(locations.filter((item) => item.selected))}
        {...(!associationsRemoved && { intent: 'danger' })}
      />
    </Flex>
  );

  return (
    <Flex marginTop={2}>
      <CssResetStyle />

      {isLoading && <LoadingState size="medium" />}

      {!isLoading && isEmpty(locations) && (
        <EmptyState
          title={t('empty_locations')}
          icon={iconix.Building}
          size="medium"
        />
      )}

      {!isLoading && !isEmpty(locations) && (
        <PatchedTable
          columns={columns}
          data={locations}
          getRowKey={({ id }) => id}
          footer={footer}
          toolbar={toolbar}
        />
      )}
    </Flex>
  );
}

AssociatedLocationsTable.propTypes = {
  associationsRemoved: PropTypes.bool,
  currentDealerId: PropTypes.number,
  userId: PropTypes.number.isRequired,
  onSingleAction: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  refreshTrigger: PropTypes.number.isRequired,
};

AssociatedLocationsTable.defaultProps = {
  associationsRemoved: false,
  currentDealerId: null,
};

export default AssociatedLocationsTable;

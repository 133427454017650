import { useState, useCallback } from 'react';
import fetchUsers from '../services/usersService';

const useUsers = ({ dealerId, endpoint }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
  });

  const fetchUsersData = useCallback(
    async ({ page = 1, sortBy = null, sortOrder = null } = {}) => {
      try {
        setIsLoading(true);
        setError(null);

        const data = await fetchUsers({
          dealerId,
          endpoint,
          page,
          sortBy,
          sortOrder,
        });

        setUsers(data.users);
        setPagination(data.pagination);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [dealerId, endpoint],
  );

  return {
    users,
    isLoading,
    error,
    pagination,
    fetchUsersData,
  };
};

export default useUsers;

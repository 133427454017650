/* eslint-disable import/prefer-default-export */
export const t = (key) => {
  const translations = {
    current: 'Current',
    locations: 'Locations',
    empty_locations: 'No Locations',
    current_access_title: 'Current Access',
    current_access_desc: 'User has access to the following locations.',
    removed_access_title: 'Removed Access',
    removed_access_desc:
      'User no longer has access to the following locations.',
    show_removed_access: 'Show Removed Access',
    hide_removed_access: 'Hide Removed Access',
    'table_header.location_name': 'Location Name',
    'table_header.id': 'ID',
    'table_header.location': 'City State',
    'table_header.roles': 'Permissions',
    'table_header.removed': 'Removed',
    'table_header.actions': 'Actions',
    action_remove_access: 'Remove Access',
    action_restore_access: 'Restore Access',
    action_bulk_remove: 'Remove Access to selected locations',
    action_bulk_restore: 'Restore Access to selected locations',
  };
  return translations[key] || '-missing-';
};
